var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "boxed-layout" }, [
    _c("h5", [_vm._v("Personen Auswahl")]),
    _vm._v(" "),
    !_vm.showSubCustomerSelection
      ? _c("div", [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "customerSearch" } }, [
              _vm._v("Kunde suchen"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.customerSearchText,
                    expression: "customerSearchText",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "customerSearch",
                  disabled: _vm.isLoading,
                  placeholder: "Kunde suchen",
                  type: "text",
                },
                domProps: { value: _vm.customerSearchText },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.searchCustomers()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.customerSearchText = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "ml-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    staticStyle: { "min-width": "150px" },
                    attrs: {
                      disabled:
                        _vm.customerSearchText === null ||
                        _vm.customerSearchText.length < 3 ||
                        _vm.isLoading,
                      type: "button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.searchCustomers()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.isLoading ? "Lädt..." : "Suchen") +
                        "\n\n            "
                    ),
                    !_vm.isLoading
                      ? _c("font-awesome-icon", { attrs: { icon: "search" } })
                      : _c("font-awesome-icon", {
                          attrs: { icon: "spinner", spin: "" },
                        }),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "small",
              {
                staticClass: "form-text text-muted",
                attrs: { id: "customerSearchHelp" },
              },
              [_vm._v("Geben Sie den Namen ein")]
            ),
          ]),
          _vm._v(" "),
          _vm.foundCustomers && _vm.foundCustomers.length > 0
            ? _c(
                "div",
                {
                  staticClass: "customer-wrapper card card-body mt-3",
                  staticStyle: { "max-height": "500px", "overflow-y": "auto" },
                },
                _vm._l(_vm.foundCustomers, function (customer) {
                  return _c("div", { key: customer.id }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn w-100 text-left",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.updateSelectedMainCustomer(customer)
                          },
                        },
                      },
                      [
                        _vm.selectedMainCustomer?.fremdId == customer.fremdId
                          ? _c("font-awesome-icon", {
                              attrs: { icon: ["fas", "circle"] },
                            })
                          : _c("font-awesome-icon", {
                              attrs: { icon: ["far", "circle"] },
                            }),
                        _vm._v(
                          "\n          " +
                            _vm._s(customer.anrede) +
                            " " +
                            _vm._s(customer.title ?? "") +
                            " " +
                            _vm._s(customer.lastName) +
                            "\n          " +
                            _vm._s(customer.firstName) +
                            "\n          " +
                            _vm._s(customer.titleAfter ?? "") +
                            "\n          "
                        ),
                        customer.dob
                          ? _c("span", { staticClass: "small text-muted" }, [
                              _vm._v(
                                "\n            (" +
                                  _vm._s(_vm.formatDate(customer.dob)) +
                                  ")\n          "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", [
            _vm.foundCustomers && _vm.foundCustomers.length === 0
              ? _c("div", { staticClass: "alert alert-secondary" }, [
                  _vm._v("\n        Es wurden keine Kunden gefunden.\n      "),
                ])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showSubCustomerSelection
      ? _c("div", [
          _vm.isLoading
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "alert alert-secondary text-center" },
                  [
                    _c("p", [_vm._v("Zugehörige Personen werden geladen...")]),
                    _vm._v(" "),
                    _c("font-awesome-icon", {
                      attrs: { icon: "spinner", spin: "" },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.foundSubCustomers && _vm.foundSubCustomers.length > 0
            ? _c(
                "div",
                {
                  staticClass: "customer-wrapper card card-body mt-3",
                  staticStyle: { "max-height": "500px", "overflow-y": "auto" },
                },
                [
                  _vm.selectedMainCustomer
                    ? _c("p", { staticClass: "font-weight-bold" }, [
                        _vm._v("\n        Zugehörige Personen zu\n        "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "text-decoration": "underline",
                              "text-decoration-thickness": "3px",
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.selectedMainCustomer.lastName) +
                                " " +
                                _vm._s(_vm.selectedMainCustomer.firstName) +
                                ":\n        "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.foundSubCustomers, function (customer, indx) {
                    return _c("div", { key: customer.id }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedCustomers,
                            expression: "selectedCustomers",
                          },
                        ],
                        attrs: {
                          id: indx + "_sub_c",
                          disabled: customer.isMainCustomer,
                          type: "checkbox",
                        },
                        domProps: {
                          value: customer,
                          checked: Array.isArray(_vm.selectedCustomers)
                            ? _vm._i(_vm.selectedCustomers, customer) > -1
                            : _vm.selectedCustomers,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.selectedCustomers,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = customer,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.selectedCustomers = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.selectedCustomers = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.selectedCustomers = $$c
                            }
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("label", { attrs: { for: indx + "_sub_c" } }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(customer.anrede) +
                            " " +
                            _vm._s(customer.title ?? "") +
                            " " +
                            _vm._s(customer.lastName) +
                            "\n          " +
                            _vm._s(customer.firstName) +
                            "\n          " +
                            _vm._s(customer.titleAfter ?? "") +
                            "\n          "
                        ),
                        customer.dob
                          ? _c("span", { staticClass: "small text-muted" }, [
                              _vm._v(
                                "\n            (" +
                                  _vm._s(_vm.formatDate(customer.dob)) +
                                  ")\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        customer.relationship
                          ? _c("span", [
                              _vm._v(
                                " - " + _vm._s(customer.relationship) + " "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ])
                  }),
                  _vm._v(" "),
                  !_vm.foundSubCustomers || _vm.foundSubCustomers.length === 0
                    ? _c("div", [
                        _c("small", { staticClass: "text-muted" }, [
                          _vm._v("Keine zugehörigen Personen gefunden"),
                        ]),
                      ])
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(
              "\n      (" +
                _vm._s(_vm.DEFAULT_SELECTED_RELATIONSHIPS.join(", ")) +
                " werden automatisch vorausgewählt)\n    "
            ),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.selectedCustomers && _vm.selectedCustomers.length > 0
      ? _c("div", { staticClass: "card card-body mt-3" }, [
          _c("p", { staticClass: "font-weight-bold" }, [_vm._v("Ausgewählt:")]),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.selectedCustomerNames))]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "report-button-wrapper text-right mt-3" }, [
      _vm.showSubCustomerSelection
        ? _c(
            "button",
            {
              staticClass: "btn",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.cancelMainSelect()
                },
              },
            },
            [_vm._v("\n      Zurück\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSubCustomerSelection
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                disabled:
                  !_vm.selectedCustomers || _vm.selectedCustomers.length === 0,
                type: "button",
              },
              on: {
                click: function ($event) {
                  return _vm.incStep()
                },
              },
            },
            [
              _vm._v("\n      Weiter\n      "),
              _c("font-awesome-icon", { attrs: { icon: "chevron-right" } }),
            ],
            1
          )
        : _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { disabled: !_vm.selectedMainCustomer, type: "button" },
              on: {
                click: function ($event) {
                  return _vm.mainCustomerSelected()
                },
              },
            },
            [
              _vm._v("\n      Weiter zur Auswahl\n      "),
              _c("font-awesome-icon", { attrs: { icon: "chevron-right" } }),
            ],
            1
          ),
      _vm._v(" "),
      _c("div", [
        !_vm.selectedCustomers || _vm.selectedCustomers.length === 0
          ? _c("small", { staticClass: "text-muted" }, [
              _vm._v(
                "\n        Bitte wählen Sie mindestens einen Kunden aus.\n      "
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }