
import { Component, Inject, Vue } from 'vue-property-decorator';

import AlertService from '@/shared/alert/alert.service';
import CustomerService from '@/entities/customer/customer.service';
import { ICustomer } from '@/shared/model/customer.model';
import { formatDateUtil } from '@/shared/formatUtils';

@Component({})
export default class CustomerSelection extends Vue {
  //search result customers
  foundCustomers: ICustomer[] | null = null;
  foundSubCustomers: ICustomer[] = [];
  isLoading = false;
  customerSearchText: string = null;
  selectedMainCustomer: ICustomer | null = null;
  showSubCustomerSelection = false;
  DEFAULT_SELECTED_RELATIONSHIPS = ['Kind', 'Ehepartner', 'Lebensgefährte'];

  @Inject('alertService') private alertService: () => AlertService;
  @Inject('customerService') private customerService: () => CustomerService;

  /**
   * get selected customer names
   */
  get selectedCustomerNames() {
    return this.selectedCustomers
      .map((customer: ICustomer) => `${customer.lastName} ${customer.firstName}`)
      .join(', ');
  }

  /**
   * get selected customers from state
   */
  get selectedCustomers(): ICustomer[] {
    return this.$store.getters.customer;
  }

  /**
   * update state customers with selected customers
   */
  set selectedCustomers(value: ICustomer[]) {
    //update store with selected customers and go to next page
    this.$store.commit('setCustomer', value);
  }

  /**
   * updates foundCustomers with customers that meet the filter criteria
   */
  async searchCustomers(): Promise<void> {
    this.isLoading = true;
    //reset selected main customer
    this.selectedMainCustomer = null;
    try {
      //get efm customers from server
      this.foundCustomers = await this.customerService().searchCustomers(this.customerSearchText);
      console.log(this.foundCustomers);
    } catch (e) {
      console.error(e);
      this.foundCustomers = [];
      this.alertService().showError(this, 'Fehler beim Laden der Kunden');
    } finally {
      this.isLoading = false;
    }
  }

  async mounted() {
    //reset store
    this.$store.commit('resetStore');
  }

  async mainCustomerSelected() {
    //go to step2
    this.showSubCustomerSelection = true;
    await this.loadSubCustomers(this.selectedMainCustomer);
  }

  async loadSubCustomers(selectedMainCustomer: ICustomer) {
    this.isLoading = true;
    try {
      //get sub customers from server
      const subCustomers = await this.customerService().getSubCustomers(selectedMainCustomer);
      //set main customer flag
      subCustomers.forEach((sc: ICustomer) => {
        if (sc.fremdId === selectedMainCustomer.fremdId) {
          sc.isMainCustomer = true;
        }
      });
      this.foundSubCustomers = subCustomers;
      const defaultSelected = this.extractDefaultSelected(subCustomers);
      this.selectedCustomers = [...defaultSelected];
    } catch (e) {
      this.alertService().showError(this, 'Fehler beim Laden der zugehörigen Kunden');
      console.error(e);
    } finally {
      this.isLoading = false;
    }
  }

  cancelMainSelect() {
    //hide sub customers
    this.showSubCustomerSelection = false;
    //reset sub customers
    this.foundSubCustomers = [];
    //reset selected customers
    this.selectedCustomers = [];
  }

  incStep() {
    //go to next page
    this.$router.push({ name: 'ReportQuestions' }).then();
  }

  updateSelectedMainCustomer(customer: ICustomer) {
    //unset if already selected
    if (this.selectedMainCustomer?.fremdId == customer.fremdId) {
      this.selectedMainCustomer = null;
      return;
    }
    //set
    this.selectedMainCustomer = customer;
  }

  formatDate(date: string): string {
    return formatDateUtil(date);
  }

  /**
   * extract main customer and all with relationship in default selected
   * @param {ICustomer[]} subCustomers - sub customers
   * @return {ICustomer[]} - default selected customers
   */
  extractDefaultSelected(subCustomers: ICustomer[]): ICustomer[] {
    //extract main customer and all with relationship in default selected
    return subCustomers.filter(
      (sc: ICustomer) =>
        sc.isMainCustomer || this.DEFAULT_SELECTED_RELATIONSHIPS.includes(sc.relationship)
    );
  }
}
